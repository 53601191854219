import { ModuleSize } from '@movecloser/page-builder/lib/contracts'

import { ModuleBase, ModuleFactory } from '../../contexts'

import { AvailableModuleDriver } from '../allowed'
import { ReviewsTeaserModule, ReviewsTeaserModuleData } from './ReviewsTeaser.contracts'
import { abstractModuleFactory, getDefaultModuleAlignment } from '../abstract/form'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const reviewsTeaserContentFactory = (): ReviewsTeaserModuleData => ({
  hasSummary: false,
  heading: null,
  reviewsCount: 0,
  reviewsPerPage: 0
})

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const reviewsTeaserFactory: ModuleFactory<ReviewsTeaserModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: true,
      maxColSpan: 12,
      maxRowSpan: 4,
      minColSpan: 6,
      minRowSpan: 4,
      rowSpan: 4
    }
  },
  createModule (): ModuleBase<ReviewsTeaserModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: reviewsTeaserContentFactory(),
      driver: AvailableModuleDriver.ReviewsTeaser
    }
  }
}
